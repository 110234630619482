import React from "react"
import PropTypes from "prop-types"

import InstagramEmbed from 'react-instagram-embed'

import styleVariables from '../styles/variables'

const {
  colors: { secondary },
  mediaQueries: { sml, medUp, bigUp },
  padding: { basePadding }
} = styleVariables



const Instagram = ({id, type}) => {
  let medPadding, bigMargin, maxWidth;

  switch (type) {
    case 'right':
      medPadding = `${basePadding} 0 ${basePadding} ${basePadding}`
      bigMargin = `-${basePadding} -5rem 0 0`
      maxWidth = `290`
      break;
    case 'left':
      medPadding = `${basePadding} ${basePadding} ${basePadding} 0 `
      bigMargin = `-${basePadding} 0 0 -5rem`
      maxWidth = `290`
      break;
    case 'none':
    default:
      medPadding = `${basePadding}`
      bigMargin = `0 auto`
  }

  const instagramStyle = {
    margin: `${basePadding} 0`,
    minWidth: `auto !important`,
    background: secondary,
    textAlign: `center`,
    [sml]: {
      maxWidth: `calc(100vw - 2.9rem)`,
      overflow: `hidden`,
    },
    [medUp]: {
      maxWidth: `calc(290px + 2.9rem)`,
      margin: `-${basePadding} 0 ${basePadding}`,
      float: type,
      padding: medPadding,
      width: `auto`
    },
    [bigUp]: {
      margin: bigMargin,
      padding: `${basePadding}`
    }
  }
  
  return ( 
    <div css={instagramStyle}>
      <InstagramEmbed url={`https://instagr.am/p/${id}/`} hideCaption={true} maxWidth={maxWidth} />
    </div>
  ) 
}

Instagram.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['left', 'right', 'none'])
}

Instagram.defaultProps = {
  type: 'none'
}

export default Instagram